import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Grid, FormControlLabel, TextField, Typography, IconButton, Link } from '@mui/material';
import { toast } from 'react-toastify';
import Layout from '../Layout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomerInfoForm from '../components/customerInfoForm';
import PaymentInfoForm from '../components/paymentInfoForm';
import DOMPurify from 'dompurify';
import 'react-toastify/dist/ReactToastify.css';

export default function PublicMaterialsRequest() {
  const [products, setProducts] = useState([]);
  const [formats, setFormats] = useState([]);
  const [pastOrders, setPastOrders] = useState();
  const [isChecked, setChecked] = useState(false);
  const [orderResponse, setOrderResponse] = useState('');
  const [errorsByOrderIndex, setErrorsByOrderIndex] = useState({});
  const [orders, setOrders] = useState([createEmptyOrder()]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const { user } = useAuth();
  const REQUEST_ID = 'D1825B8B-EF69-4E61-A83C-1560FE17187D';
  const ORDER_LIMIT = 3;
  const COST_PER_ORDER = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the API to pull product data
        const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/product/${REQUEST_ID}`);
        const productData = await productResponse.json();
        setProducts(productData.data);

        // Call the API to pull format data
        const formatResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/format/${REQUEST_ID}`);
        const formatData = await formatResponse.json();
        setFormats(formatData.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [REQUEST_ID]); // Include the dependency array with the REQUEST_ID

  // Group products by category_id
  const groupedProducts = {};

  products.forEach((product) => {
    if (!groupedProducts[product.category_id]) {
      groupedProducts[product.category_id] = [];
    }
    groupedProducts[product.category_id].push(product);
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => { // Add accordion change event handler
    setExpanded(isExpanded ? panel : false);
  };

  const handleProductChange = (orderIndex, event) => {
    const selectedProductId = event.target.value;

    const updatedOrders = orders.map((order, index) => {
      if (index === orderIndex) {
        // Initialize orderQuestions based on selected product's questions
        const selectedProductQuestions = productQuestions[selectedProductId];
        const orderQuestions = selectedProductQuestions.map((question) => ({
          question: question.question,
          answer: '',
        }));

        return { ...order, selectedProduct: selectedProductId, orderQuestions };
      }
      return order;
    });

    setOrders(updatedOrders);
  };

  const productQuestions = {
    "CCB4AF94-5458-462C-BF90-BE3ABAFC987E": [
      { question: "Title*", answer: "" },
      { question: "Author", answer: "" },
      { question: "Call number / Su Doc number", answer: "" },
      { question: "Volume / section / page numbers*", answer: "" },
      { question: "Additional Information", answer: "" },
    ],
    "0D5EC6A0-FB95-448D-A663-025C09FF56AB": [
      { question: "Name(s)*", answer: "" },
      { question: "Date of event (within one week)*", answer: "" },
      { question: "Date published if known", answer: "" },
      { question: "Title of newspaper*", answer: "" },
      { question: "Location of marriage or death*", answer: "" },
      { question: "Additional Information", answer: "" },
    ],
    "FA08C1C2-EACE-40B7-A928-7C07DCBAAAFD": [
      { question: "Title of newspaper / magazine*", answer: "" },
      { question: "Article subject or title*", answer: "" },
      { question: "Volume / date*", answer: "" },
      { question: "Section / page(s) if known", answer: "" },
      { question: "Additional Information", answer: "" },
    ]
  }

  const productQuestionHeading = {
    "CCB4AF94-5458-462C-BF90-BE3ABAFC987E": [
      { text: "1-20 pages in one source per request.  Up to 30 pages total per source may be requested.  Additional pages above 20 will be billed for at .50 per page plus sales tax and shipping if applicable." },
    ],
    "0D5EC6A0-FB95-448D-A663-025C09FF56AB": [
      { text: "One name or married couple per request" },
    ],
    "FA08C1C2-EACE-40B7-A928-7C07DCBAAAFD": [
      { text: "1-20 pages. Additional pages (10 max) will be billed at .50 per page" },
    ]
  }

  const handleOrderQuestionChange = (orderIndex, questionIndex, event) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].orderQuestions[questionIndex].answer = event.target.value;
    setOrders(updatedOrders);
  };

  const handleFormatChange = (orderIndex, formatId) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].format = formatId; // Set the format as the selected format_id
    setOrders(updatedOrders);
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const addOrder = () => {
    if (orders.length < ORDER_LIMIT) {
      setOrders([...orders, createEmptyOrder()]);
    }
  };

  const calculateTotalAmount = () => {
    const currentOrderCount = orders.length;
    return currentOrderCount * COST_PER_ORDER;
  };

  const removeOrder = (orderIndex) => {
    const updatedOrders = [...orders];
    updatedOrders.splice(orderIndex, 1);
    setOrders(updatedOrders);
  };

  const validateForm = () => {
    const errors = [];
    const accordionErrors = [];
    const errorsByOrderIndex = {};

    orders.forEach((order, orderIndex) => {

      // Initialize an empty array for orderIndex
      errorsByOrderIndex[orderIndex] = [];

      if (!order.selectedProduct) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Type of material not selected.`);
        accordionErrors.push(orderIndex);
      }

      // Find the question within the order's questions
      const newspaperObituaryMarriageNoticeDate = order.orderQuestions.find((question) => question.question === "Date of event (within one week)*");
      const newspaperObituaryMarriageNoticeName = order.orderQuestions.find((question) => question.question === "Name(s)*");
      const newspaperObituaryMarriageNoticeTitle = order.orderQuestions.find((question) => question.question === "Title of newspaper*");
      const newspaperObituaryMarriageNoticeLocation = order.orderQuestions.find((question) => question.question === "Location of marriage or death*");
      
      const bookGovernmentDocumentTitle = order.orderQuestions.find((question) => question.question === "Title*");
      const bookGovernmentDocumentVolumeSection = order.orderQuestions.find((question) => question.question === "Volume / section / page numbers*");

      const magazineNewspaperTitle = order.orderQuestions.find((question) => question.question === "Title of newspaper / magazine*");
      const magazineNewspaperArticle = order.orderQuestions.find((question) => question.question === "Article subject or title*");
      const magazineNewspaperVolumeDate = order.orderQuestions.find((question) => question.question === "Volume / date*");


      // Check if magazineNewspaperTitle is available and its answer is empty
      if (magazineNewspaperTitle && !magazineNewspaperTitle.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Title of newspaper / magazine* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if magazineNewspaperArticle is available and its answer is empty
      if (magazineNewspaperArticle && !magazineNewspaperArticle.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Article subject or title* is required.`);
        accordionErrors.push(orderIndex);
      }

       // Check if magazineNewspaperVolumeDate is available and its answer is empty
       if (magazineNewspaperVolumeDate && !magazineNewspaperVolumeDate.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Volume / date* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if bookGovernmentDocumentTitle is available and its answer is empty
      if (bookGovernmentDocumentTitle && !bookGovernmentDocumentTitle.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Title* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if bookGovernmentDocumentVolumeSection is available and its answer is empty
      if (bookGovernmentDocumentVolumeSection && !bookGovernmentDocumentVolumeSection.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Volume / section / page numbers* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if newspaperObituaryMarriageNoticeName is available and its answer is empty
      if (newspaperObituaryMarriageNoticeName && !newspaperObituaryMarriageNoticeName.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Name(s)* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if the question is available and its answer is empty
      if (newspaperObituaryMarriageNoticeDate && !newspaperObituaryMarriageNoticeDate.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Date of event (within one week)* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if newspaperObituaryMarriageNoticeTitle is available and its answer is empty
      if (newspaperObituaryMarriageNoticeTitle && !newspaperObituaryMarriageNoticeTitle.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Title of newspaper* is required.`);
        accordionErrors.push(orderIndex);
      }

      // Check if newspaperObituaryMarriageNoticeLocation is available and its answer is empty
      if (newspaperObituaryMarriageNoticeLocation && !newspaperObituaryMarriageNoticeLocation.answer) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Location of marriage or death* is required.`);
        accordionErrors.push(orderIndex);
      }

      if (!order.format) {
        errorsByOrderIndex[orderIndex].push(`Order #${orderIndex + 1}: Format not selected.`);
        accordionErrors.push(orderIndex);
      }

    });

    setExpanded(accordionErrors[0]);
    setErrorsByOrderIndex(errorsByOrderIndex);

    return errorsByOrderIndex;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form Submitted');
    setExpanded();

    const errorsByOrderIndex = validateForm();
    const totalErrorsCount = Object.values(errorsByOrderIndex)
      .reduce((total, errorsArray) => total + errorsArray.length, 0);

    if (totalErrorsCount > 0) {
      console.log("Invalid");

      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return;
    }

    // Disable the submit button while form is being submitted
    setIsSubmitting(true);

    // Set transaction id variable 
    const TRANSACTION_ID = uuidv4().toUpperCase();

    // Sanitizate Inputs 
    const customerName = DOMPurify.sanitize(event.target.name.value).replace(/[^a-zA-Z\s]/g, '');
    const customerEmail = DOMPurify.sanitize(event.target.email.value);
    const customerAddress = DOMPurify.sanitize(event.target.address.value).replace(/\./g, '').trim();
    const customerCity = DOMPurify.sanitize(event.target.city.value).trim();
    const customerState = event.target.state ? DOMPurify.sanitize(event.target.state.value).trim() : '';
    const customerCountry = event.target.country ? DOMPurify.sanitize(event.target.country.value).trim() : 'United States';
    const customerZip = DOMPurify.sanitize(event.target.zip.value).trim();
    const customerPhone = DOMPurify.sanitize(event.target.phone.value).replace(/\D/g, '');

    // Get the customer information from the form fields
    const customer = {
      name: customerName,
      email: customerEmail,
      address: `${customerAddress} ${customerCity}, ${customerState}, ${customerZip}, ${customerCountry}`,
      phone: customerPhone,
    };

    // Get card information from the form field
    const card = {
      name: customerName,
      company: "Library of Virginia",
      phone: customerPhone,
      email: customerEmail,
      number: event.target.cardNumber.value,
      expirationDate: event.target.expirationDate.value,
      cvv: event.target.cardCode.value,
      amount: calculateTotalAmount(),
      address: customerAddress,
      city: customerCity,
      state: customerState,
      zip: customerZip,
      country: customerCountry,
      transaction_id: TRANSACTION_ID,
      user: user ? user.user_id : null
    }

    // This builds the request body for order limit
    const limitReq = {
      user: user ? user.user_id : null,
      email: customerEmail,
      phone: customerPhone,
      request_id: REQUEST_ID,
    };

    // Prepare the data for submission
    const submittedOrders = orders.map((order) => {
      const submittedOrderDetails = productQuestions[order.selectedProduct].map((question) => ({
        question: question.question,
        answer: order.orderQuestions.find((q) => q.question === question.question)?.answer || '',
      }));

      return {
        product: order.selectedProduct,
        format: order.format,
        details: submittedOrderDetails,
      };
    });

    // Call API to check order count
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order/limit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(limitReq),
      });
      const json = await response.json();
      setPastOrders(json.data);

      // This calculates the current order amount
      const currentOrderCount = submittedOrders.length + pastOrders;

      // Run checks before charging card and comitting to DB 
      if (currentOrderCount > ORDER_LIMIT) {
        toast.error(`Unfortunately you have reached the request limit of 8 this month. This order will take you over the threshold: ${currentOrderCount}/${ORDER_LIMIT}`);
        setIsSubmitting(false);
        return; // Stop the form submission
      }

    } catch (error) {
      toast.error(error);
      setIsSubmitting(false);
      return;
    }

    // Call API to process payment
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payment/converge`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(card),
      });

      const xmlResponse = await response.text(); // Get the XML response as text
      
      if (response.status === 500) {
        toast.error(`${xmlResponse}`);
        setIsSubmitting(false);
        return;
      }

    } catch (error) {
      toast.error(`Payment Error: ${error}`);
      setIsSubmitting(false);
      return;
    }

    // Create the final payload
    const payload = {
      orders: submittedOrders,
      customer: customer,
      user: user ? user.user_id : null,
      request: REQUEST_ID,
      transaction: TRANSACTION_ID
    };

    // Call API to process order 
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      setOrderResponse(response);

    } catch (error) {
      toast.error(`Error Processing Order: ${error}`);
      setIsSubmitting(false);
      return;
    }

    // Add a slight delay before clearing the form fields
    setTimeout(() => {
      // Set formSubmitted to true before clearing the payment fields
      setFormSubmitted(true);

      // Clear the form fields
      event.target.reset();

      // Set the form fields in the state back to their initial values
      setOrders([createEmptyOrder()]);
      setExpanded(false);

      // Reset isSubmitting to false after the form submission is complete
      setIsSubmitting(false);

      // Display success message
      toast.success('Order Fulfilled. Check Email!');
    }, 200);
  };

  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Published Materials Request
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br></br>
              <div>
                <Typography variant="body1" paragraph>
                  The Library of Virginia can fulfill up to three requests for reproductions of published materials per person/business/address per month.  Researchers need to provide citations as library staff cannot conduct research on a subject or look through multiple sources for family history.
                </Typography>

                <Typography variant="body1" paragraph>
                  Before placing an order, it is highly recommended to contact Library Reference Services at 804.692.3777 or {' '}
                  <Link href="mailto:refdesk@lva.virginia.gov" color="inherit">
                    refdesk@lva.virginia.gov
                  </Link>.{' '} 
                </Typography>

                <Typography variant="body1" paragraph>
                  Please be aware that a search may not locate the information that you seek. Staff will list the sources checked and provide further research suggestions if possible.
                </Typography>

                <Typography variant="body1" paragraph>
                  A non-refundable $10 service fee is required for each request.
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                      <ListItem sx={{ display: 'list-item' }}>Up to 20 photocopies or PDF scans from a single book, newspaper, magazine, or government document, or</ListItem>
                      <ListItem sx={{ display: 'list-item' }}>One marriage notice or obituary if found.</ListItem>
                    </List>
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Staff do not copy entire works.</strong> For requests that are more than 20 pages, up to 10 additional pages will be billed at $0.50 per page plus sales tax and shipping if appropriate.  
                </Typography>

                <Typography variant="body1" paragraph>
                  Please allow 3 to 7 business days for email delivery and 1-2 weeks for regular mail delivery.
                  For materials with a location of “Boxed Newspapers” or “Special Collections”, please submit a Special Collections request.
                </Typography>

                <Typography variant="body1">
                  For full-color or high-resolution reproductions, please contact Library Reference Services. 
                </Typography>

              </div>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {Object.values(errorsByOrderIndex).reduce((total, errorsArray) => total + errorsArray.length, 0) > 0 && (
                <Box marginBottom={2} sx={{ border: '1px', borderRadius: '4px', padding: '16px' }}>
                  <Alert severity="error">
                    <AlertTitle>Validation Errors</AlertTitle>
                    There are errors in the following order(s):
                    <ul>
                      {Object.keys(errorsByOrderIndex).map((orderIndex) => (
                        <li key={orderIndex}>{`Order #${parseInt(orderIndex) + 1}`}</li>
                      ))}
                    </ul>
                  </Alert>
                </Box>
              )}
              {/* Orders */}
              {orders.map((order, orderIndex) => (
                <Accordion key={orderIndex} expanded={expanded === orderIndex} onChange={handleAccordionChange(orderIndex)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h6" gutterBottom style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                          Order #{orderIndex + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {orderIndex > 0 && (
                          <IconButton
                            style={{ color: 'red' }}
                            onClick={() => removeOrder(orderIndex)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box marginBottom={2} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px' }}>
                      <Grid container spacing={2}>
                        {/* Product Questions */}
                        <Grid item xs={12}>
                          {errorsByOrderIndex[orderIndex] && errorsByOrderIndex[orderIndex].length > 0 && (
                            <Box marginBottom={2} sx={{ border: '1px', borderRadius: '4px', padding: '16px' }}>
                              <Alert severity="error">
                                <AlertTitle>Order #{orderIndex + 1} Error(s)</AlertTitle>
                                Please correct the following error(s):
                                <ul>
                                  {errorsByOrderIndex[orderIndex].map((error, index) => (
                                    <li key={index}>{error}</li>
                                  ))}
                                </ul>
                              </Alert>
                            </Box>
                          )}
                          <Typography variant="h6" gutterBottom>
                            Type of Material
                          </Typography>
                          {Object.entries(groupedProducts).map(([categoryId, categoryProducts]) => (
                            <Grid container spacing={2}>
                              {categoryProducts.map((product) => (
                                <Grid item xs={12} key={product.product_id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={order.selectedProduct === product.product_id}
                                        onChange={(event) => handleProductChange(orderIndex, event)}
                                        value={product.product_id}
                                      />
                                    }
                                    label={product.product_name}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ))}
                        </Grid>

                        {/* Order Questions */}
                        <Grid item xs={12}>
                          {order.selectedProduct && (
                            <div key={orderIndex}>
                              {productQuestionHeading[order.selectedProduct].map((text, textIndex) => (
                                <Typography variant="h6" gutterBottom>
                                  {text.text}
                                </Typography>
                              ))}
                              {productQuestions[order.selectedProduct].map((question, questionIndex) => (
                                <Box mb={2} key={questionIndex}>
                                  <TextField
                                    label={question.question}
                                    name={question.question}
                                    value={order.orderQuestions[questionIndex].answer || ''}
                                    onChange={(event) => handleOrderQuestionChange(orderIndex, questionIndex, event)}
                                    fullWidth
                                  />
                                </Box>
                              ))}
                            </div>
                          )}
                        </Grid>

                        {/* Format */}
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            Format (Choose one)
                          </Typography>
                          {formats.map((format) => (
                            <FormControlLabel
                              key={format.format_id}
                              control={
                                <Checkbox
                                  checked={order.format === format.format_id}
                                  onChange={() => handleFormatChange(orderIndex, format.format_id)}
                                />
                              }
                              label={format.format_name}
                              name="format"
                            />
                          ))}
                        </Grid>

                        {orders.length > 1 && (
                          <Grid item xs={12}>
                            <Button variant="outlined" onClick={() => removeOrder(orderIndex)}>
                              Remove Order
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            {orders.length < ORDER_LIMIT && (
              <Grid item xs={12}>
                <Button variant="outlined" onClick={addOrder}>
                  Add Order
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
             <Typography variant="h6" component="h6" gutterBottom>
                Total: ${calculateTotalAmount().toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerInfoForm />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" gutterBottom>
                Payment Details (VISA or MasterCard only)
              </Typography>
              <PaymentInfoForm formSubmitted={formSubmitted} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
              If you have any questions about your request, please contact Library Reference Services at {' '}
                <Link href="mailto:refdesk@lva.virginia.gov" color="inherit">
                  refdesk@lva.virginia.gov
                </Link>{' '}
                or 804.692.3777 before submitting your order.
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="I understand that the service fee is non-refundable."
              />
            </Grid>
            {isChecked &&
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Submit'}
                </Button>
              </Grid>
            }
          </Grid>
        </form>
      </Box>
    </Layout>
  );
}

// Utility function to create an empty order object
function createEmptyOrder() {
  return {
    selectedProduct: '',
    orderQuestions: [],
    format: ''
  };
}