import Layout from '../Layout';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function RequestTypes() {
    const requests = [
        { text: 'Archival Materials Requests', path: '/request/archives' },
        { text: 'Published Materials Requests', path: '/request/published-materials' },
        { text: 'Special Collections Requests', path: '/request/special-collections' }
    ];

  return (
    <Layout>
      <Box display="flex" justifyContent="center" alignItems="center">
      {requests.map((request) => (
        <Button
          key={request.text}
          component={Link}
          to={request.path}
          variant="contained"
          color="primary"
          style={{ margin: '0.5rem' }}
        >
          {request.text}
        </Button>
      ))}
    </Box>
    <br></br>
    <Typography variant="h6" component="h6" gutterBottom>
      Please review the following information before proceeding to the online research request form:
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>Published materials include newspapers, books, magazines and government documents.</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Archival records include state agency records, county and city court records (wills, deeds, etc.) and private collections such as family papers, church records, business or organization records.</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Special Collections materials include rare books, prints, photographs, broadsides, ephemera, architecture and maps.</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Costs vary depending on the type of material and format you’re requesting.</ListItem>
          <ListItem sx={{ display: 'list-item' }}>If you have any questions regarding your request, please contact the appropriate department before submitting your order.</ListItem>
        </List>
    </Typography>
    </Layout>
  );
}
